export const embaucheFCTHeaders = [
  {
    text: "TAC",
    value: "trigramme",
    sortable: false,
    align: 'center',
  },
  {
    text: "Date de signature",
    value: "date_signature",
    sortable: false,
    align: 'center',
  },
  {
    text: "Mois de signature",
    value: "mois_signature",
    sortable: false,
    align: 'center',
  },
  {
    text: "Annee de signature",
    value: "annee_signature",
    sortable: false,
    align: 'center',
  },
  {
    text: "Date démarrage contrat",
    value: "date_entree",
    sortable: false,
    align: 'center',
  },
  {
    text: "Mois démarrage contrat",
    value: "mois_entree",
    sortable: false,
    align: 'center',
  },
  {
    text: "Annee démarrage contrat",
    value: "annee_entree",
    sortable: false,
    align: 'center',
  },
  {
    text: "Date d'entrée mc2i",
    value: "entree_mc2i",
    sortable: false,
    align: 'center',
  },
  {
    text: "Matricule",
    value: "matricule",
    sortable: false,
    align: 'center',
  },
  {
    text: "Emploi",
    value: "profilFCT",
    sortable: false,
    align: 'center',
  },
  {
    text: "Nom",
    value: "nom",
    sortable: false,
    align: 'center',
  },
  {
    text: "Prenom",
    value: "prenom",
    sortable: false,
    align: 'center',
  },
  {
    text: "Sexe",
    value: "sexe",
    sortable: false,
    align: 'center',
  },
  {
    text: "Ecole",
    value: "nom_ecole",
    sortable: false,
    align: 'center',
  },
  {
    text: "Type d'école",
    value: "type_ecole",
    sortable: false,
    align: 'center',
  },
  {
    text: "Rang école",
    value: "rang_ecole",
    sortable: false,
    align: 'center',
  },
  {
    text: "Entité",
    value: "label_entite",
    sortable: false,
    align: 'center',
  },
  {
    text: "Pôle",
    value: "pole",
    sortable: false,
    align: 'center',
  },
  {
    text: "Type de contrat",
    value: "label_contrat",
    sortable: false,
    align: 'center',
  },
  // {
  //   text: "HP",
  //   value: "high_potential",
  //   sortable: false,
  //   align: 'center',
  // },
  {
    text: "Salaire",
    value: "salaires",
    sortable: false,
    align: 'center',
  },
  {
    text: "Date d'ancienneté pro",
    value: "anciennete",
    sortable: false,
    align: 'center',
  },
  {
    text: "Origine",
    value: "origine",
    sortable: false,
    align: 'center',
  },
  {
    text: "Actions",
    value: "actions",
    sortable: false,
    align: 'center',
  },
];
